<script>
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

export default {
    props: {
        options: Object,
        rootDomain: String,
        policy: String,
    },
    computed: {
        showByReferrer() {
            return (
                this.policy == "showByReferrer" &&
                !document.referrer.includes(this.rootDomain)
            );
        },
    },
    mounted() {
        Fancybox.bind(this.$refs.container, "[data-fancybox]", {
            ...(this.options || {}),
        });

        if (this.policy == "alwaysShow" || this.showByReferrer) {
            this.$refs.container.querySelector("[data-fancybox]").click();
        }
    },
    updated() {
        Fancybox.unbind(this.$refs.container);
        Fancybox.close();

        Fancybox.bind(this.$refs.container, "[data-fancybox]", {
            ...(this.options || {}),
        });
    },
    methods: {
        close() {
            Fancybox.close();
        },
    },
    unmounted() {
        Fancybox.destroy();
    },
};
</script>

<template>
    <div ref="container">
        <slot v-bind="{ close }"></slot>
    </div>
</template>

<style></style>
