import "./scss/global.scss";
import "swiper/css";
import { createApp } from "vue";
import { useBreakpoints } from "@vueuse/core";
import { register } from "swiper/element/bundle";
import { ExpandTransition, FadeTransition } from "@bam/transitions";
import { OnClickOutside } from "@vueuse/components";

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept();
}

const app = createApp({
    data: () => {
        return {
            // globalSwiperModules: [Pagination, Parallax],
        };
    },

    mounted() {
        register();
    },
});

const components = import.meta.glob("./components/**/*.vue", { eager: true });
Object.entries(components).forEach(([path, definition]) => {
    const componentName = path
        .split("/")
        .pop()
        .replace(/\.\w+$/, "");
    app.component(componentName, definition.default);
});

app.component("expand-transition", ExpandTransition);
app.component("fade-transition", FadeTransition);
app.component("on-click-outside", OnClickOutside);

app.mount("#app");
